export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42')
];

export const server_loads = [];

export const dictionary = {
		"/": [6],
		"/components/addon_rent_articles_modal": [8,[2]],
		"/components/addon_rent_article": [7,[2]],
		"/components/change_selection_menu": [9,[2]],
		"/components/depot_modal": [10,[2]],
		"/components/global_addon_article": [11,[2]],
		"/components/global_collapse_closable": [13,[2]],
		"/components/global_collapse": [12,[2]],
		"/components/global_dialog": [14,[2]],
		"/components/global_modal": [15,[2]],
		"/components/info_collapse": [16,[2]],
		"/components/person_information_form": [17,[2]],
		"/components/rent_action": [18,[2]],
		"/components/rent_availability": [19,[2]],
		"/components/rent_category_header": [21,[2]],
		"/components/rent_category_products": [22,[2]],
		"/components/rent_category": [20,[2]],
		"/components/rent_form": [23,[2]],
		"/components/rent_model_modal": [25,[2]],
		"/components/rent_model": [24,[2]],
		"/components/rent_product": [26,[2]],
		"/components/rental_period_input": [28,[2]],
		"/components/rental_period": [27,[2]],
		"/components/shop_page_header": [29,[2]],
		"/components/shop_selection_page_header": [31,[2]],
		"/components/shop_select": [30,[2]],
		"/components/shopping_cart_button": [33,[2]],
		"/components/shopping_cart_item": [34,[2]],
		"/components/shopping_cart": [32,[2]],
		"/components/sub_category": [35,[2]],
		"/components/successful_checkout": [36,[2]],
		"/components/type_select": [37,[2]],
		"/iframes/shop_main_page": [38,[3]],
		"/pages/checkout_page": [39,[4]],
		"/pages/shop_main_page": [40,[4]],
		"/pages/shop_selection_page": [41,[4]],
		"/tests": [42,[5]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';